
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { PrismicPreview } from 'prismic/preview';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'ui/components/ErrorBoundary/ErrorBoundary';
import { useViewport } from 'ui/hooks/useViewport';
import { Layout } from 'ui/layout/Layout';
import { turingLogger } from 'ui/services/logger';
import { ImpervaUserInfo } from 'ui/types/imperva-user-info';
import { AppContext, AppContextDefaultValue, IAppContext } from 'ui/utils/app-context';
import { pageview } from 'ui/utils/external-scripts/GAScripts';
import { turingLoggerPageView } from 'ui/utils/external-scripts/TuringLogger';
import { getFeatures, getUserId, trackingCallbackHandler } from 'ui/utils/growthbook/client';
import { storageSession } from 'ui/utils/helpers/session-helper';
import { HeadScripts } from '~/component/external-scripts/HeadScripts';
import { LogPageView, flushQueue, eventQueue, resetEventQueue } from 'ui/utils/analytics/InternalLogger';
import '../styles/global.css';
import '../styles/scss/main.scss';
import { Poppins } from 'next/font/google';
const poppins = Poppins({
    subsets: ['latin'],
    display: 'swap',
    variable: '--font-poppins',
    weight: ['100', '200', '300', '400', '500', '600', '700']
});
const growthbook = new GrowthBook({
    trackingCallback: trackingCallbackHandler
});
let isFirstPageView = true;
function MyApp({ Component, pageProps }) {
    if (Component.defaultProps?.isSliceSimulator) {
        return <Component {...pageProps}/>;
    }
    const { events, asPath, pathname } = useRouter();
    const [visitorPayload, setVisitorPayload] = useState<IAppContext>(AppContextDefaultValue);
    const [impervaUserInfoPayload, setImpervaUserInfoPayload] = useState<ImpervaUserInfo | null>(null);
    useEffect(() => {
        getFeatures().then(result => {
            const [features, impervaUserInfo] = result;
            console.debug('impervaUserInfo', impervaUserInfo);
            setImpervaUserInfoPayload({ ...impervaUserInfo });
            growthbook.setFeatures(features);
        });
        growthbook.setAttributes({
            id: getUserId()
        });
        // Handle global color change on scroll
        document.addEventListener('scroll', () => {
            const elements = document.querySelectorAll('.color-changing-section');
            elements.forEach(element => {
                const rect = element.getBoundingClientRect();
                if (rect.top <= 400) {
                    // Dispatch a custom event with the background color
                    const bgColor = element.getAttribute('data-background');
                    if (pathname === '/') {
                        const event = new CustomEvent('backgroundColorChange', { detail: { color: bgColor } });
                        window.dispatchEvent(event);
                    }
                }
            });
        });
        () => {
            document.removeEventListener('scroll', () => { });
        };
    }, []);
    useEffect(() => {
        if (pathname === '/') {
            // first time dispatch regarding bg change on home page
            const event = new CustomEvent('backgroundColorChange', { detail: { color: 'dark' } });
            window.dispatchEvent(event);
        }
    }, [pathname]);
    useEffect(() => {
        events.on('routeChangeComplete', executePageView);
        turingLogger.registerActivityTracker();
        return () => {
            events.off('routeChangeComplete', executePageView);
            (turingLogger as any).unregisterActivityTracker();
        };
    }, [events]);
    useEffect(() => {
        try {
            turingLoggerPageView().then(visitor => {
                setVisitorPayload({ ...visitorPayload, visitor });
                console.debug('visitor', visitor);
                isFirstPageView = false;
                LogPageView(asPath, visitor?.visitorId);
            });
            storageSession();
            window.addEventListener('beforeunload', event => {
                flushQueue();
            });
            window.addEventListener('blur', () => {
                flushQueue();
            });
            window.addEventListener('focus', () => {
                // Optionally, you can trigger a flush when the tab gains focus again
                flushQueue();
            });
            window.addEventListener('offline', () => {
                localStorage.setItem('eventQueue', JSON.stringify(eventQueue));
                resetEventQueue([]);
            });
            window.addEventListener('online', () => {
                const storedQueue = JSON.parse(localStorage.getItem('eventQueue')) || [];
                resetEventQueue(storedQueue.concat(eventQueue));
                localStorage.removeItem('eventQueue');
                flushQueue();
            });
        }
        catch { }
        () => {
            window.removeEventListener('beforeunload', () => { });
            window.removeEventListener('blur', () => { });
            window.removeEventListener('focus', () => { });
            window.removeEventListener('offline', () => { });
            window.removeEventListener('online', () => { });
        };
    }, []);
    const executePageView = url => {
        if (!isFirstPageView) {
            pageview(url);
            // Log the page view to dreamdata
            LogPageView(url, visitorPayload.visitor?.visitorId);
        }
    };
    const pageRef = useRef(null);
    const triggerCallbackOnAppLevel = () => {
        // perform any action in the page
        if (pageRef.current.type === 'OPEN_CALENDLY_WIDGET') {
            pageRef.current.openRoutForm();
        }
    };
    const viewport = useViewport();
    return (<>
      <Head>
        <meta name="viewport" content={viewport}/>
        <meta name="google-site-verification" content="N3I6EG67_CyZ3FE_w9Z_TzyoY9iZMydnYaXU3hHRfMM"/>
        <meta property="og:logo" content="https://images.prismic.io/turing/Zsw74kaF0TcGJYsO_Turing-GreyLogo-FullLockup-Horizontal.png?auto=format,compress"/>
      </Head>

      <NextNProgress color="#2e6edf" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} options={{ showSpinner: false }}/>

      <Script id="osano" strategy="lazyOnload" defer src="https://cmp.osano.com/AzZKOfTrHQqdYQTHM/c2b4c5c0-a64a-4955-81dc-ec9b8fdcbe86/osano.js"/>
      <HeadScripts />
      <div className={poppins.variable}>
        <ErrorBoundary>
          <AppContext.Provider value={{ ...visitorPayload, impervaUserInfo: impervaUserInfoPayload }}>
            <GrowthBookProvider growthbook={growthbook}>
              <PrismicPreview>
                <Layout headerProps={pageProps.headerProps} 
    // headerData={pageProps.headerData}
    footerProps={pageProps.footerProps} triggerCallbackOnAppLevel={triggerCallbackOnAppLevel}>
                  <div id="dropdownPortalElement"></div>
                  <div id="imagePopupPortalElement"></div>
                  <div id="dropdownSubHeaderPortalElement"></div>
                  <Component {...pageProps} pageTriggerRef={pageRef}/>
                </Layout>
              </PrismicPreview>
            </GrowthBookProvider>
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    </>);
}
const __Next_Translate__Page__192711a317e__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__192711a317e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  