import { asLink } from '@prismicio/client';
import cn from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, ButtonAppearance } from 'ui/components/Button';
import { CustomLink } from 'ui/components/CustomLink';
import { NavBarBreakpoint } from 'ui/components/ResponsiveImage';
import { useScrollY } from 'ui/hooks/useScrollY';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { constants } from 'ui/utils/constants';
import { SVGLibrary } from 'ui/svgs/library';
import Search from 'ui/components/Search';
import { useFeature } from '@growthbook/growthbook-react';
import { HeaderService } from 'ui/services/header/header.service';
import GetStartedModal from '../GetStarted/GetStartedModal';
import { getSearchResults } from '../../../../apps/web/service/elasticsearch';
import styles from './Header.module.scss';

export const Header = () => {
  const scrollY = useScrollY();
  const isFixedHeader = scrollY > 0;
  const [_document, set_document] = React.useState(null);
  const [activeDropDown, setActiveDropDown] = useState('');
  const [width] = useWindowsSize();
  const [navOpen, setNavOpen] = useState(false);
  const [showCTAOptions, setShowCTAOptions] = useState(false);
  const { t, lang } = useTranslation('header');
  const headerData: any = t('.', {}, { returnObjects: true });
  const [openGetStarted, setOpenGetStarted] = useState(false);
  const router = useRouter();

  const { value: searchFeatureConfig } = useFeature('search-rollout-geo');
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (searchFeatureConfig) {
      HeaderService.getCountry().then(country => {
        setShowSearch((searchFeatureConfig?.allowedCountries || []).includes(country));
      });
    }
  }, [searchFeatureConfig]);

  const handleDropDownClick = useCallback(
    (id: string) => () => {
      setActiveDropDown(state => (state === id ? '' : id));
    },
    [],
  );
  useEffect(() => {
    set_document(document);
  }, []);

  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    function handleClickOutside(event) {
      if (width > NavBarBreakpoint) {
        const ele = document.getElementById(activeDropDown);
        const mainEle = document.getElementById(`top_nav_wrapper_${activeDropDown}`);
        if (ele && !ele.contains(event.target) && !mainEle.contains(event.target)) {
          setActiveDropDown('');
        }
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [activeDropDown, width]);

  const handleDropdownClose = useCallback(() => {
    setActiveDropDown('');
    setNavOpen(false);
  }, []);
  if (!headerData.items) {
    return;
  }
  const renderCTAs = () => {
    return (
      <div className={styles.addNav}>
        {showSearch && (
          <Search
            onSubmit={q => router.push({ pathname: '/search', query: { q }})}
            getQuickResults={(query) => getSearchResults(query, { size: 3 })}
          />
        )}

        {headerData.items
          .filter(item => item.alignment_type === 'CTA')
          .map((item, i) => {
            const { text } = item;
            const isMenu = item.type === 'Menu';
            const isCTA = item.type === 'CTA';
            return (
              <>
                <div
                  className={cn(styles.navListItem, styles.navListItemWithoutBorder, {
                    [styles.noPadding]: isCTA,
                    [styles.marginLeft]: !isCTA,
                    [styles.navListItemActive]: activeDropDown === text.toLowerCase().split(' ').join('_'),
                  })}
                  key={i}
                  role="presentation"
                  id={`top_nav_wrapper_${text.toLowerCase().split(' ').join('_')}`}
                  // Note the code below is hard coded to open the Login options only
                  {...(isMenu
                    ? {
                      onClick:
                        width < NavBarBreakpoint + 1
                          ? () => {
                            setShowCTAOptions(true);
                          }
                          : handleDropDownClick(text.toLowerCase().replaceAll(' ', '_')),
                    }
                    : {})}
                >
                  <Button
                    className={cn(styles.navLinkCTA, {
                      [styles.nonCTA]: !isCTA,
                    })}
                    // outline
                    text={text}
                    {...(isCTA
                      ? {
                        onClick: () => {
                          if (item.cta_type === 'Get Started') {
                            if (width > NavBarBreakpoint) {
                              setOpenGetStarted(true);
                            } else {
                              // redirect
                              router.push('/get-started');
                              setNavOpen(false);
                            }
                          }
                        },
                      }
                      : {
                        ...(width < NavBarBreakpoint + 1
                          ? { outline: true }
                          : { appearance: ButtonAppearance.LinkWhiteV2, postIcon: <ArrowDown /> }),
                      })}
                    id={`top-nav-${text.toLowerCase().split(' ').join('-')}`}
                    {...(isMenu ? { onClick: e => e.preventDefault() } : {})}
                    {...(!(isMenu && isCTA) ? { href: asLink(item.link) } : {})}
                  />
                </div>
                {_document && isMenu && (
                  <RenderDropdown item={item} activeDropDown={activeDropDown} setActiveDropDown={handleDropdownClose} />
                )}
                {isMenu && (
                  <div
                    className={cn(styles.buttonMenu, {
                      [styles['buttonMenu-active']]: showCTAOptions,
                    })}
                  >
                    <div className={styles.header}>
                      <div onClick={() => setShowCTAOptions(false)} className={styles.backButton}>
                        <SVGLibrary name="ExpandLess" /> Back
                      </div>
                      <div className={styles.text}>Login</div>
                    </div>
                    {item.navigation_list.data.navigation_links.map((navLink, index) => {
                      return (
                        <div className={cn(styles.itemWrapper, styles.extraPadding)}>
                          {navLink.link_to_document.data.navigation_items.map(navItem => {
                            return (
                              <div
                                onClick={() => {
                                  handleDropdownClose();
                                }}
                              >
                                <Button
                                  className={cn(styles.navLinkCTA, styles.noMargin, {
                                    [styles.nonCTA]: !isCTA,
                                  })}
                                  // outline
                                  text={navItem.label}
                                  {...(isCTA
                                    ? {
                                      onClick: e => alert('Get Started'),
                                    }
                                    : {
                                      ...(width < NavBarBreakpoint + 1
                                        ? { outline: true }
                                        : { appearance: ButtonAppearance.LinkWhiteV2, postIcon: <ArrowDown /> }),
                                    })}
                                  id={`top-nav-${navItem.label.toLowerCase().split(' ').join('-')}`}
                                  href={asLink(navItem.link)}
                                  target={navItem.link_target}
                                />
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            );
          })}
      </div>
    );
  };
  return (
    <div className={cn(styles.headerContainer)}>
      <header data-elastic-exclude className={cn(styles.frame, isFixedHeader && styles.fixedHeader)}>
        <div className={cn('wide-container', styles.layoutWrapper)}>
          <div className={styles.layout}>
            <CustomLink href="/" prefetch={false}>
              <a className={styles.logo} id="top-nav-cta-turing-logo">
                <img loading="eager" alt="logo" src={'/img/logo_256.webp'} width={124} height={45} srcSet="/img/logo_128.webp 1x, /img/logo_256.webp 2x" />
              </a>
            </CustomLink>

            {/* Navigation Left side */}
            <div
              className={cn(styles.menu, {
                [styles['menu-active']]: navOpen,
              })}
            >
              <nav className={styles.nav}>
                <ul className={styles.navList}>
                  {headerData.items
                    .filter(item => item.alignment_type === 'Navigation')
                    .map((item, i) => {
                      const { text } = item;
                      const isMenu = item.type === 'Menu';
                      const id = text.toLowerCase().split(' ').join('_');
                      return (
                        <>
                          <li
                            {...(isMenu ? { onClick: handleDropDownClick(id) } : { onClick: handleDropdownClose })}
                            className={cn(styles.navListItem, {
                              [styles.navListItemActive]: activeDropDown === id,
                              [styles.LinkWrapper]: !isMenu,
                            })}
                            key={i}
                            role="presentation"
                            id={`top_nav_wrapper_${id}`}
                          >
                            <Button
                              className={cn(styles.navLink, {
                                [styles.Link]: !isMenu,
                              })}
                              text={text}
                              appearance={ButtonAppearance.LinkWhiteV2}
                              id={`top-nav-${text.toLowerCase().split(' ').join('-')}`}
                              {...(isMenu ? { onClick: e => e.preventDefault() } : { href: asLink(item.link) })}
                            />
                            {isMenu &&
                              (width < NavBarBreakpoint + 1 ? (
                                activeDropDown !== id ? (
                                  <SVGLibrary name='ExpandMore' />
                                ) : (
                                  <SVGLibrary name='ExpandLess' />
                                )
                              ) : (
                                <ArrowDown />
                              ))}
                          </li>
                          {_document && isMenu && (
                            <RenderDropdown
                              item={item}
                              activeDropDown={activeDropDown}
                              setActiveDropDown={handleDropdownClose}
                            />
                          )}
                        </>
                      );
                    })}
                </ul>
              </nav>
              {width < NavBarBreakpoint + 1 && renderCTAs()}
            </div>
          </div>
          {/* CTA's */}
          {width > NavBarBreakpoint && renderCTAs()}
          <div className={styles.rightButtonsContainer}>
            {showSearch && (
              <Search
                onSubmit={q => router.push({ pathname: '/search', query: { q }})}
                getQuickResults={(query) => getSearchResults(query, { size: 3 })}
              />
            )}
            <div className={styles.menuOpener}>
              {navOpen ? (
                <Image
                  src={'/img/Hamburger_close.svg'}
                  onClick={() => setNavOpen(state => !state)}
                  width={48}
                  height={48}
                  alt="Hamburger_close.svg"
                  loading="eager"
                />
              ) : (
                <Image
                  src={'/img/Hamburger_menu.svg'}
                  onClick={() => setNavOpen(state => !state)}
                  width={48}
                  height={48}
                  alt="Hamburger_menu.svg"
                />
              )}
            </div>
          </div>
        </div>
        <GetStartedModal open={openGetStarted} onClose={() => setOpenGetStarted(false)} />
      </header>
    </div>
  );
};
const RenderDropdown = ({ item, setActiveDropDown, activeDropDown }) => {
  const getLeftValue = useCallback(id => {
    const rects = document.getElementById(id)?.getBoundingClientRect();
    return rects?.left;
  }, []);
  const [width] = useWindowsSize();
  const { text } = item;
  const dropdown = (
    <>
      {item.drop_down_type === 'Full Width' ? (
        <div
          id={text && text.toLowerCase().replaceAll(' ', '_')}
          style={{
            display: activeDropDown === (text && text.toLowerCase().replaceAll(' ', '_')) ? 'flex' : 'none',
            // left:
            //       width < NavBarBreakpoint + 1 && item.drop_down_type !== 'Full Width'
            //         ? 0
            //         : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
          }}
          className={styles.rootFullWidthWrapper}
        >
          <div
            className={styles.fullWidthWrapper}
            style={{
              gridTemplateColumns:
                width > NavBarBreakpoint
                  ? `${(item.navigation_list.data.navigation_links.length === 2 ? '1.5fr ' : '1fr ').repeat(
                    item.navigation_list.data.navigation_links.length,
                  )}  1.7fr`
                  : '1fr',
            }}
          >
            {item.navigation_list.data.navigation_links.map((navLink, index) => {
              return (
                <div className={styles.itemWrapper}>
                  <div className={styles.heading}>{navLink.item_text}</div>
                  {navLink.link_to_document.data.navigation_items.map(navItem => {
                    return (
                      <LinkComponent
                        withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                        navItem={navItem}
                        setActiveDropDown={setActiveDropDown}
                      >
                        <>
                          {navItem.icon.url && (
                            <div>
                              <Image
                                className={styles.icon}
                                src={navItem.icon.url}
                                width={24}
                                height={24}
                                alt={navItem.label}
                              />
                            </div>
                          )}
                          <div>
                            <div>{navItem.label}</div>
                            {navItem.description && <p>{navItem.description}</p>}
                          </div>
                        </>
                      </LinkComponent>
                    );
                  })}
                </div>
              );
            })}
            {item.resource_post_link && (
              <div className={styles.resourceWrapper}>
                <div className={styles.title}>{item.resource_title}</div>
                <Image width={160} height={105} src={item.resource_post_link.data.image.url} alt="" />
                <div className={styles.paraTitle}>{item.resource_post_link.data.title}</div>
                <p className={styles.paragraph}>{item.resource_post_link.data.description}</p>
                <CustomLink href={item.resource_post_link.url}>
                  <a onClick={() => setActiveDropDown('')} className={styles.read_more}>
                    {item.resource_read_more_text}
                  </a>
                </CustomLink>
                <CustomLink href={item.see_all_resources_link}>
                  <a onClick={() => setActiveDropDown('')} className={styles.see_all}>
                    {item.see_all_resources_text}
                  </a>
                </CustomLink>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Render the content width dropdown
        <div
          id={text.toLowerCase().replaceAll(' ', '_')}
          style={{
            display: activeDropDown === text.toLowerCase().replaceAll(' ', '_') ? 'block' : 'none',
            left:
              width < NavBarBreakpoint + 1 && item.drop_down_type !== 'Full Width'
                ? 0
                : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
          }}
          className={styles.rootContentFitWrapper}
        >
          <div
            style={{
              left:
                width < NavBarBreakpoint + 1
                  ? 0
                  : `${getLeftValue(`top_nav_wrapper_${text.toLowerCase().replaceAll(' ', '_')}`)}px`,
            }}
            className={styles.conentFitWrapper}
          >
            {item.navigation_list.data.navigation_links.map((navLink, index) => {
              return (
                <div className={styles.itemWrapper}>
                  {navLink.link_to_document.data.navigation_items.map(navItem => {
                    return (
                      <LinkComponent
                        withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                        navItem={navItem}
                        setActiveDropDown={setActiveDropDown}
                      >
                        <div>
                          <div>{navItem.label}</div>
                          {navItem.description && <p>{navItem.description}</p>}
                        </div>
                      </LinkComponent>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
  return width > NavBarBreakpoint ? createPortal(dropdown, document.getElementById('dropdownPortalElement')) : dropdown;
};

export const LinkComponent = ({ withCustomLink, navItem, setActiveDropDown, children }) => {
  return withCustomLink ? (
    <Button target={navItem.link_target} href={asLink(navItem.link)} appearance={ButtonAppearance.InvisibleLink}>
      <a className={styles.itemCard} onClick={() => setActiveDropDown('')}>
        {children}
      </a>
    </Button>
  ) : (
    <a
      className={styles.itemCard}
      href={navItem.link?.url}
      target={navItem.link_target}
      onClick={() => setActiveDropDown('')}
    >
      {children}
    </a>
  );
};

export const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_905_3305)">
        <path
          d="M8.70834 11.71L11.2983 14.3C11.6883 14.69 12.3183 14.69 12.7083 14.3L15.2983 11.71C15.9283 11.08 15.4783 10 14.5883 10H9.40834C8.51834 10 8.07834 11.08 8.70834 11.71Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_905_3305">
          <rect width="24" height="24" fill="white" transform="translate(-0.00170898)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Header;
